<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'viewport', content: 'initial-scale=1, maximum-scale=1' },
      { name: 'keywords', content: 'стадион, паркове, градини, трева, настилки, изкуствена трева, акрилни настилки, спорт, терени, винилови настилки, естествени, продукти' },
      { name: 'description', content: 'Сайт за Техно Спорт България, настилки за стадиони, паркове, градини и други' },
      { name: 'author', content: 'Dimitar Katsarov' },
    ],
  },
  computed: {
    title() {
      return this.$t('title.value');
    },
  },
  created() {
    document.title = this.title;
  },
  mounted () {
    document.body.classList.add('main-layout');
  },
  destroyed () {
    document.body.classList.remove('main-layout');
  },
}
</script>

<style lang="scss">
@import '@/assets/css/bootstrap.min.css';
@import '@/assets/css/style.css';
@import '@/assets/css/responsive.css';
@import 'https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.5/jquery.fancybox.min.css';

body, html {
  scroll-behavior: smooth;
}
</style>
