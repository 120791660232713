const bg = {
    бг: {
        title: {
            value: 'Техно Спорт България'
        },
        home: {
            value: 'Начало'
        },
        products: {
            value: 'Продукти'
        },
        gallery: {
            value: 'Галерия'
        },
        contacts: {
            value: 'Контакти'
        },
        logo: {
            value: 'ТСБ'
        },
        bannerText: {
            value: "настилки за стадиони, паркове, градини и други"
        },
        preview: {
            value: 'Преглед'
        },
        bulgaria: {
            value: 'България'
        },
        verified: {
            value: 'Доказан опит в '
        },
        hottestbox: {
            value: `Техно Спорт България е специализирана в настилки за стадиони,
            паркове, детски площадки, градини и други. Разпогаме с голям
            набор от настилки естествени и изкуствени. Осигуряваме
            консултации при избор на най-подходящата настилка, разработка на
            идеен проект, доставка, реализация и поддръжка.`
        },
        whyChooseUs: {
            value: `Защо да изберете нас?`
        },
        whyChooseUsText: {
            value: `Иновативна компания, съчетаваща любов към спорта, прецизност към
            изпълнението, разбиране и грижа към изискванията на своите
            клиенти, радост и гордост при реализирането на проекта.`
        },
        aboutUs: {
            value: `Ние сме`
        },
        aboutUsText: {
            value: `екип от специалисти в областта на спортната
              инфраструктура, изпълнени с желание нещата да се случват
              по нов начин, удовлетворението от успешната и прецизна
              реализация на проекта и радостта в очите на нашите
              партньори са най–голямата ни награда.`
        },
        weOffer: {
            value: 'Предлагаме'
        },
        weOfferText: {
            value: ` всичко в областта на спорта , подовите настилки
            включително PVC настилки , винилови настилки, мокети
            ,ламиниран и естествен паркет , настилки от корк
            ,епоксидни настилки и 3Д епоксидни подови настилки.`
        },
        quality: {
            value: 'Качество'
        },
        qualityText: {
            value: `Държим на качеството, изпълняваме проектите прецизно и в
            срок.`
        },
        naturalSportTerrain: {
            value: 'Естествени спортни терени'
        },
        naturalSportTerrainText: {
            value: `Имаме едни от най-добрите агрономи и специалисти по изграждане,
            отглеждане и поддръжка на естествени терени, единствените които
            показват завидни резултати за България и не само. В процес на
            работа и с времето подбрахме най-подходящите видове семена за
            нашите климатични условия и ги съобразяваме с микроклимата, в
            който засяваме естествените терени. Торовете, които използваме
            за подхранване на естествените настилки, са изпробвани във
            времето и са доказали качествата си. Макар и по-трудни за
            поддръжка, естествените тревни настилки са предпочитани от
            футболистите, защото причиняват по-малко травми. Голяма част от
            футболните срещи се провеждат на естествени терени, защото са
            доказали своите качества. Ние предлагаме всякакъв вид решения за
            вашата естествена настилка, имаме усет към детайла и не се
            задоволяваме с посредствени резултати. Грижим се за нуждите и
            желанията на клиентите, свързани с естествената трева, знаем как
            да поддържаме тренировъчни и официални терени. Можем да ви дадем
            решение как да подобрите визията и качеството на вашата трева,
            без значение дали е двор, общински общи площи или официален
            футболен стадион. Всичко започва с желанието, нуждата и
            предназначението на естествената настилка, която желаете да имате.`
        },
        artificialGrass: {
            value: 'Изкуствена трева'
        },
        artificialGrassText: {
            value: `<p><b>Доверете се на нашата изкуствена трева.</b></p>
              <p>
                Нашата изкуствена трева съответства напълно на изискванията на
                FIFA. Качеството е гарантирано от сертификати според
                европейските, холандски, френски, швейцарски и немски стандарти,
                които са сред най-строгите в света. Тя е устойчива, трайна,
                осигурява изключителен комфорт при игра, не се налага да се коси
                и подсява. Лабораторните изследвания показват, че продуктите
                спокойно издържат над 50 000 цикъла на употреба, което означава
                между 10 и 15 години при нормално натоварване на терените.
              </p>
              <p><b>Изкуствена трева – дълготрайно и лесно решение.</b></p>
              <p>
                Едно от основните преимущества на изкуствената трева е огромното
                разнообразие от възможности за поставянето ѝ. Тя се свързва
                най-вече с употреба на спортни терени заради страхотната
                издръжливост на покритието и неговата дълготрайност. Заради тези
                нейни качества тя може да се поставя в спортни зали, в които
                тренират деца и в училища. Изключително подходяща е и за външна
                употреба на спортни съоръжения и площадки, защото не се влияе от
                климатичните условия. Тя е приятно покритие за събития на
                открито или закрито, щандове на изложения, барове, ресторанти с
                открити веранди и добавя страхотен акцент навсякъде.
              </p>`
        },
        acrylicFlooring: {
            value: 'Акрилни настилки'
        },
        acrylicFlooringText: {
            value: `<p><b>Повече, отколкото очаквате</b></p>
            <p>
              Те са подходящи за спортни зали, външни и вътрешни спортни
              игрища, спортни площадки и др. Акрилните настилки имат висока
              износоустойчивост и устойчивост на атмосферни влияния, което ги
              прави използваеми целогодишно. Имат високо шумопоглъщане и
              високо ударопоглъщане благодарение на своята основа от ситен
              каучуков гранулат Същевременно със своята еластичност те
              осигуряват комфорт на спортуващите върху тях и мекота при
              падане. Произведени са от екологично чисти материали и съставки.
              Дебелината на акрилните системи варира от 4 до 12 мм. Подходяща
              основа за нейното полагане е асфалт или бетон.
            </p>`
        },
        vinylFlooring: {
            value: 'Винилови настилки'
        },
        vinylFlooringText: {
            value: `<p><b>Събудете дизайнера в себе си</b></p>
            <p>
              Виниловите настилки са модерно решение за всеки дом, офис,
              ресторант, хотел или заведение.
            </p>
            <p>Те се делят на няколко класа:</p>
            <p>
              <b>Residential Flooding</b> – В този клас са виниловите
              настилки, които се полагат в жилища. Те са подходящи за всички
              помещения включително и банята, тъй като при полагането им
              отделните парчета се заваряват със заваръчен шнур чрез топла
              заварка.
            </p>
            <p>
              <b>Semi – Commercial Flooring</b> – В този клас попадат
              виниловите настилки, които са подходящи както за домашна
              употреба, така и за публични места, където натоварването не е
              толкова високо. Такива са детски ясли, градини и др.
            </p>
            <p>
              <b>Commercial Flooring</b> – В този клас попадат настилките,
              подходящи за публични места и помещения с изключително високо
              натоварване. Такива са болници, офиси, хотели, училища,
              производствени помещения, цехове, хранително вкусовата
              промишленост и др.
            </p>
            <p><b>Винилови настилки на руло</b></p>
            <p>
              Това са най-срещаните и най-използваните настилки на пазара, тъй
              като за тяхното производство се изразходват най-малко средства и
              ресурси. Те се използват за мокри помещения,
              хранително-вкусовата промишленост, кухни и др., където е
              възможно разливане на течности, повече от които са мазни и
              хлъзгави. При виниловите настилки на руло е задължително
              лепенето при тяхното полагане и заваряването със заваръчен шнур
              чрез топла заварка.
            </p>
            <p>
              <b>Винилови настилки на плочи и планки</b>
            </p>
            <p>
              Виниловите настилки на плочи и планки навлизат трайно на нашия
              пазар и стават изключително предпочитан продукт заради своя
              дизайн. Те биват с различни размери и начин на полагане.
              Виниловите плочи и планки биват с различна основа за полагане в
              различните помещения.
            </p>`
        },
        selfLevelingRubberFlooring: {
            value: 'Саморазливни каучукови настилки'
        },
        selfLevelingRubberFlooringText: {
            value: `<p><b>Иновативната технология</b></p>
            <p>
              Тази иновативна технология има отлични ударопоглъщащи качества,
              висока издръжливост на натоварване и устойчивост на
              неблагоприятни климатични условия. Широкото приложение на
              висококачествените саморазливни каучукови настилки е друго
              преимущество, които ги прави предпочитани. Какво представляват
              тези настилки и как се монтират? Изработват се от слепени
              каучукови гранули и фибри, които образуват хомогенна смес.
              Позволяват монтаж на всевъзможни локации, като настилките се
              монтират към предварително подготвена гладка асфалтова или
              бетонова основа.
            </p>`
        },
        fullName: {
            value: 'Пълно име'
        },
        email: {
            value: 'Е-майл'
        },
        phoneNumber: {
            value: 'Телефонен номер'
        },
        message: {
            value: 'Съобщение'
        },
        send: {
            value: 'Изпрати'
        },
        sofia: {
            value: 'София'
        },
        navigation: {
            value: 'Навигация'
        },
        emailSent: {
            value: 'Е-майла беше изпратен успешно!'
        },
    }
};

export default bg