import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router'
import VueMeta from 'vue-meta'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'
import en from '@/locale/en'
import bg from '@/locale/bg'

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(VueAxios, axios);
Vue.use(VueI18n);

const messages = {
  ...bg,
  ...en,
};

const i18n = new VueI18n({
  locale: 'бг',
  messages
});

new Vue({
  vuetify,
  router,
  render: h => h(App),
  i18n,
}).$mount('#app')
