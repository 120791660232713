const en = {
  en: {
    title: {
      value: 'Techno Sport Bulgaria'
    },
    home: {
      value: 'Home'
    },
    products: {
      value: 'Products'
    },
    gallery: {
      value: 'Gallery'
    },
    contacts: {
      value: 'Contacts'
    },
    logo: {
      value: 'TSB'
    },
    bannerText: {
      value: 'flooring for stadions, parks, gardens and others'
    },
    preview: {
      value: 'Preview'
    },
    bulgaria: {
      value: 'Bulgaria'
    },
    verified: {
      value: 'Expertise in '
    },
    hottestbox: {
      value: `Techno Sport Bulgaria specializes in flooring for stadiums,
      parks, playgrounds, gardens and others. Let's start with a big one
      a set of natural and artificial flooring. We provide
      consultations in choosing the most suitable flooring, development of
      conceptual design, delivery, implementation and maintenance.`
    },
    whyChooseUs: {
      value: `Why choose us?`
    },
    whyChooseUsText: {
      value: `An innovative company combining love for sports, precision to
      the fulfillment, understanding and care for the requirements of their own
      customers, joy and pride in the realization of the project.`
    },
    aboutUs: {
      value: `We are`
    },
    aboutUsText: {
      value: `a team of specialists in the field of sports
      infrastructure, full of desire to make things happen
      in a new way, the satisfaction of the successful and precise
      realization of the project and the joy in the eyes of ours
      partners are our greatest reward.`
    },
    weOffer: {
      value: 'We offer '
    },
    weOfferText: {
      value: `everything in the field of sports, flooring
            including PVC flooring, vinyl flooring, carpets
            ,laminated and natural parquet, cork flooring,
            epoxy flooring and 3D epoxy flooring.`
    },
    quality: {
      value: 'Quality'
    },
    qualityText: {
        value: `We care about quality, we execute projects precisely and in
        term.`
    },
    naturalSportTerrain: {
      value: 'Natural sport terrain'
    },
    naturalSportTerrainText: {
      value: `We have some of the best agronomists and construction specialists,
      cultivation and maintenance of natural terrains, the only ones that
      show enviable results for Bulgaria and beyond. In process of
      work and over time we selected the most suitable types of seeds for
      our climatic conditions and we adapt them to the microclimate, in
      which we sow the natural terrains. The fertilizers we use
      for nourishing the natural pavements, have been tested in
      time and have proven their qualities. Although more difficult to
      maintenance, natural grass surfaces are preferred by
      football players because they cause fewer injuries. Large part of the
      football matches are held on natural pitches because they are
      proved their qualities. We offer all kinds of solutions for
      your natural flooring, we have an eye for detail and we don't
      settle for mediocre results. We take care of the needs and
      customer wishes related to natural grass, we know how
      to maintain training and official grounds. We can give you
      a solution on how to improve the look and quality of your grass,
      regardless of whether it is a yard, municipal common areas or official
      Football field. Everything starts with the desire, the need and
      the purpose of the natural flooring you wish to have.`
    },
    artificialGrass: {
      value: 'Artifical grass'
    },
    artificialGrassText: {
      value: `<p><b>Trust our artificial grass.</b></p>
      <p>
        Our artificial grass fully complies with the requirements of
        FIFA. Quality is guaranteed by certificates according to
        European, Dutch, French, Swiss and German standards,
        which are among the strictest in the world. It is sustainable, durable,
        provides exceptional comfort when playing, does not have to be mowed
        and sows. Laboratory tests show that the products
        easily withstand over 50,000 cycles of use, which means
        between 10 and 15 years under normal terrain load.
      </p>
      <p><b>Artificial grass - a long-lasting and easy solution.</b></p>
      <p>
        One of the main advantages of artificial grass is the huge
        variety of placement options. It connects
        mostly with the use of sports fields because of the great
        durability of the coating and its durability. Because of these
        qualities it can be placed in sports halls in which
        they train children in schools as well. It is also extremely suitable for outdoor use
        use of sports facilities and grounds because it is not affected by
        climatic conditions. It is a nice cover for events on
        outdoor or indoor, stands at exhibitions, bars, restaurants with
        open porches and adds a great accent anywhere.
      </p>`
    },
    acrylicFlooring: {
      value: 'Acrylic flooring'
    },
    acrylicFlooringText: {
      value: `<p><b>More than you expect</b></p>
      <p>
        They are suitable for gyms, outdoor and indoor sports
        playgrounds, sports grounds, etc. Acrylic flooring has a high
        wear resistance and weather resistance, which makes them
        usable all year round. They have high noise absorption and
        high shock absorption thanks to its fine base
        rubber granulate. At the same time with its elasticity they
        provide comfort to those who exercise on them and softness at
        fall. They are made from environmentally friendly materials and ingredients.
        The thickness of acrylic systems varies from 4 to 12 mm. Appropriate
        the basis for its laying is asphalt or concrete.
      </p>`
    },
    vinylFlooring: {
      value: 'Vinyl flooring'
    },
    vinylFlooringText: {
      value: `<p><b>Awaken the designer in you</b></p>
      <p>
      Vinyl flooring is a modern solution for every home, office,
      restaurant, hotel or establishment.
      </p>
      <p>
      They are divided into several classes:</p>
      <p>
        <b>Residential Flooding</b> – In this class are the vinyl ones
        flooring that is laid in dwellings. They are suitable for every
        premises including the bathroom, because when laying them
        the individual pieces are welded with welding cord.
      </p>
      <p>
        <b>Semi – Commercial Flooring</b> – In this class fall the
        vinyl flooring, which is suitable for both domestic use, 
        as well as for public places where the load is not
        so high. Such are nurseries, gardens, etc.
      </p>
      <p>
        <b>Commercial Flooring</b> – This class includes flooring,
        suitable for public places and rooms with extremely high
        load. Such are hospitals, offices, hotels, schools,
        production premises, workshops, food tasting room
        industry etc.
      </p>
      <p><b>Vinyl flooring on a roll
      </b></p>
      <p>
        These are the most common and most used flooring on the market, so
        as the least funds are spent on their production and
        resources. They are used for wet rooms,
        the food industry, kitchens, etc., where it is
        possible spillage of liquids, more of which are oily and
        slippery. With vinyl flooring on a roll, it is mandatory
        gluing when laying them and welding with a welding cord
        by hot welding.
      </p>
      <p>
        <b>Vinyl flooring in slabs and planks
        </b>
      </p>
      <p>
        Sheet and plank vinyl flooring is making its way into ours permanently
        market and become an extremely preferred product because of its
        design. They come in different sizes and laying methods.
        Vinyl plates and planks come with a different base for laying in
        the different premises.
      </p>`
    },
    selfLevelingRubberFlooring: {
      value: 'Self-leveling rubber flooring'
    },
    selfLevelingRubberFlooringText: {
      value: `<p><b>Innovative technology
      </b></p>
      <p>
        This innovative technology has excellent shock-absorbing qualities,
        high load endurance and resistance to
        unfavorable climatic conditions. The wide application of
        high-quality self-leveling rubber flooring is another
        advantage that makes them preferred. What are they?
        these floorings and how are they installed? They are made of glued
        rubber granules and fibers that form a homogeneous mixture.
        They allow installation in all kinds of locations, such as flooring
        install to pre-prepared smooth asphalt or
        concrete base.
      </p>`
    },
    fullName: {
      value: 'Full name'
    },
    email: {
        value: 'Email'
    },
    phoneNumber: {
        value: 'Phone Number'
    },
    message: {
      value: 'Message'
    },
    send: {
      value: 'Send'
    },
    sofia: {
        value: 'Sofia'
    },
    navigation: {
        value: 'Navigation'
    },
    emailSent: {
      value: 'The email has been sent!'
    },
  }
};

export default en